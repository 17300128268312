























































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss"
    })
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_cybercharge);
    }
  }
});
